import React, { useState } from "react";
import "animate.css";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; // Import icons
import image1 from "../images/Gallery/1.jpg";
import image2 from "../images/Gallery/2.jpg";
import image3 from "../images/Gallery/3.jpg";
import image4 from "../images/Gallery/4.jpg";
import image5 from "../images/Gallery/5.jpg";
import image6 from "../images/Gallery/6.jpg";
import image7 from "../images/Gallery/7.jpg";
import image8 from "../images/Gallery/8.jpg";
import image9 from "../images/Gallery/9.jpg";
import image10 from "../images/Gallery/10.jpg";
import image11 from "../images/Gallery/11.jpg";
import image12 from "../images/Gallery/12.jpg";
import image13 from "../images/Gallery/13.jpg";
import image14 from "../images/Gallery/14.jpg";
import image15 from "../images/Gallery/15.jpg";
import image16 from "../images/Gallery/16.jpg";
import image17 from "../images/Gallery/17.jpg";
import image18 from "../images/Gallery/18.jpg";
import image19 from "../images/Gallery/19.jpg";
import image20 from "../images/Gallery/20.jpg";
import image21 from "../images/Gallery/21.jpg";
import image22 from "../images/Gallery/22.jpg";
import image23 from "../images/Gallery/23.jpg";
import image24 from "../images/Gallery/24.jpg";
import image25 from "../images/Gallery/25.jpg";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    image13,
    image14,
    image15,
    image16,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
  ];

  // Open modal with specific image
  const openModal = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
  };

  // Close modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  // Navigate to the next image
  const nextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Navigate to the previous image
  const prevImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Close modal if clicked outside the image
  const handleClickOutside = (e) => {
    if (e.target.id === "modal-background") {
      closeModal();
    }
  };

  return (
    <div className="mt-16 py-4 bg-gray-200">
      <h1 className="text-center text-4xl font-bold mb-4 animate__animated animate__fadeInDown">
        Our Gallery
      </h1>
      <p className="text-center mb-6 text-lg text-gray-600 animate__animated animate__fadeInUp">
        Explore our collection of beautiful images
      </p>

      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-6 mx-auto animate__animated animate__fadeInUp">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg group transform hover:scale-105 transition-all duration-500 cursor-pointer"
            onClick={() => openModal(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index + 1}`}
              className="w-full h-72 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-all duration-500"></div>
          </div>
        ))}
      </div>

      {/* Modal for full-screen image */}
      {selectedImage && (
        <div
          id="modal-background"
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center animate__animated animate__fadeIn faster z-50"
          onClick={handleClickOutside}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-[90vw] sm:w-[80vw] md:w-[70vw] h-auto max-h-[90vh] max-w-[90vw] object-contain rounded-lg"
            />
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-1 right-1 sm:top-2 sm:right-2 md:top-4 md:right-4 text-white bg-black rounded-full p-1 sm:p-2 text-lg hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineClose size={24} /> {/* Close icon */}
            </button>

            {/* Left Arrow */}
            <button
              onClick={prevImage}
              className="absolute left-1 sm:left-2 md:left-4 top-1/2 transform -translate-y-1/2 text-white bg-black p-1 sm:p-2 rounded-full hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineLeft size={24} /> {/* Left Arrow icon */}
            </button>

            {/* Right Arrow */}
            <button
              onClick={nextImage}
              className="absolute right-1 sm:right-2 md:right-4 top-1/2 transform -translate-y-1/2 text-white bg-black p-1 sm:p-2 rounded-full hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineRight size={24} /> {/* Right Arrow icon */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
